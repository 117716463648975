body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
}

.App {
  text-align: center;
  padding: 20px;
}

h1 {
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
}

.toggle-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 1.1em;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.custom-gallery .image-gallery-slide img {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 100%;
  height: auto;
}

.image-gallery-thumbnail {
  border: 2px solid transparent;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover {
  border: 2px solid #007bff;
}

.image-gallery-description {
  font-size: 1.1em;
  font-weight: bold;
  color: #555;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5em;
  }

  .toggle-button {
    font-size: 1em;
    padding: 10px 20px;
  }

  .gallery {
    gap: 5px;
  }

  .image-gallery-slide img {
    border-radius: 4px;
    box-shadow: none;
  }
}
